@font-face {
	font-family: 'Helvetica-Bd';
	src: url('HelveticaNeueLTPro-Bd.eot') format('eot'),
    url('HelveticaNeueLTPro-Bd.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeueLTPro-Bd.woff') format('woff'),
    url('HelveticaNeueLTPro-Bd.ttf') format('truetype'),
    url('HelveticaNeueLTPro-Bd.svg') format('svg');
    font-display: swap;
	font-weight: 400;
	font-style: normal;
}

