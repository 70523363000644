// $max-row-width: 1008;
// $grid-columns: 12;
// $grid: (
//   small: (
//     from: 0,
//     to: 977,
//     gutter: 12,
//     spacer: 8
//   ),
//   large: (
//     from: 978,
//     gutter: 30
//   )
// );
// $body-font-size: 16px;

// $t: transparent;

$primary-color: #01c767;
$alert-color: #b50000;

// $default-transition: all 0.4s;

$ff-helvetica: 'HelveticaNeueCyr', serif;
$ff-helvetica-roman: 'HelveticaNeueCyr', serif;
$ff-helvetica-bd: 'Helvetica-Bd', serif;
$ff-helvetica-th: 'Helvetica-Th', serif;

$color-black: #000000;
$color-white: #ffffff;
$color-white-f3: #f3f3f3;
$color-white-e4: #e4e4e4;
$color-white-silver: #fbfbfb;
$color-semi-white: #e2e2e2;
$color-blue: #0056b3;
$color-gray: #7A7F8E;
$color-dark-gray: #333333;
$color-woody: #cebb9e;
$color-purple-nuts: #8b382e;
$color-nuts: #bb7407;
$color-silver: #C0C0C0;
$color-metallic-blue: #1C3E63;
$color-california: #4B371C;