.block-text {
    @include container(1220px);
    padding-top: 50px;
    padding-bottom: 50px;
    // .block-text__share
    &__share {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;
        span {
            font-family: $ff-helvetica-bd;
        }
        img {
            width: 34px;
            height: auto;
        }
        * {
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    @extend .admin;
}