.header {
    height: 90px;
    // .header__wrap
    &__wrap {
        position: fixed;
        width: 100%;
        background-color: $color-white;
        border-bottom: 1px solid $color-semi-white;
        z-index: 100;
    }
    // .header__container
    &__container {
        @include container();
    }
    // .header_row
    &__row {
        height: 90px;
        display: flex;
    }
    // .header__column
    &__column {
        display: flex;
        align-items: center;
        height: 100%;
        // .header__column_logo
        &_logo {
            flex: 0 0 150px;
            a {
                display: inline-block;
                width: 150px;
            }
            img {
                max-width: 100%;
            }
            &_active {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 91px;
                background: $color-white;
                padding: 0 15px;
                border-bottom: 1px solid $color-semi-white;
                z-index: 100;
                img {
                    max-width: 150px;
                }
            }
        }
        // .header__column_nav
        &_nav {
            justify-content: flex-end;
            flex: 1 1 auto;
        }
    }
    // .header__li
    &__li {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        font-family: $ff-helvetica;
        font-style: normal;
        font-weight: 200;
        margin-right: 60px;
        &:last-child {
            margin-right: 0;
        }
        transition: all 0.3s ease 0s;
        &:hover {
            color: $color-blue;
            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 3px;
                background-color: $color-blue;
                transition: all 0.3s ease 0s;
            }
        }
    }
    // .header__link
    &__link {
        height: 100%;
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        font-family: $ff-helvetica;
        font-style: normal;
        font-weight: 200;
        color: $color-black;
        transition: all 0.3s ease 0s;
        &:hover {
            color: $color-blue;
        }
    }
    // .header__sub-menu
    &__sub-menu {
        flex-direction: row-reverse;
        cursor: pointer;
        &::before {
            content: "";
            margin-left: 5px;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top: 5px solid #474747;
        }
        &:hover, &:active {
            &:before {
                transform: rotate(180deg);
                border-top: 5px solid #00559d;
            }
            .header__sub-ul {
                display: block;
                position: absolute;
                top: 90px;
                left: -22px;
                width: 300px;
                height: auto;
                border: 1px solid $color-semi-white;
                background-color: #fff;
                z-index: 22;
                @media (max-width:399px) {
                    width: 290px;
                }
                @media(min-width:600px) {
                    max-height: 80vh;
                    overflow: auto;
                }
            }
            .header__sub-link {
                display: flex;
                padding: 20px 0;
                margin: 0 20px;
                border-bottom: 1px solid $color-semi-white;
            }
        }
    }
    // .header__sub-ul
    &__sub-ul {
        display: none;
    }
    // .header__sub-li
    &__sub-li {
        &:hover {
            .header__sub-link {
                transition: all 0.3s ease 0s;
                color: $color-blue;
            }
        }
    }
    // .header__sub-link
    &__sub-link {
        color: $color-black;
    }
    // .header__ul
    &__ul {
        display: none;
        @media (min-width: 600px) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
        }
        &_active {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-top: 150px;
            padding-left: var(--padding);
            padding-right: var(--padding);
            overflow: auto;
            background: $color-white;
            z-index: 21;
            .header__li {
                display: inline-flex;
                height: auto;
                margin: 0px 0px 60px 0px;
                &:after {
                    bottom: -8px;
                }
            }
            .header__link {
                height: auto;
            }
            .header__sub-menu {
                &:hover {
                    .header__sub-ul {
                        top: 30px;
                        left: unset;
                        right: 0;
                        z-index: 2;
                    }
                }
            }
        }
    }
    // .header__burger
    &__burger {
        position: absolute;
        top: 30px;
        right: 15px;
        width: 30px;
        height: 30px;
        z-index: 105;
        @media (min-width: 600px) {
            display: none;
        }

        span {
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            background-color: $color-black;
            transition: all 0.1s ease 0s;

            &::before {
                content: "";
                position: absolute;
                top: 10px;
                width: 30px;
                height: 2px;
                background-color: $color-black;
                transition: all 0.3s ease 0s;
            }

            &::after {
                content: "";
                position: absolute;
                top: -10px;
                width: 30px;
                height: 2px;
                background-color: $color-black;
                transition: all 0.3s ease 0s;
            }
        }

        &_active {
            span {
                width: 0;

                &::before {
                    transform: rotate(-45deg);
                    top: 0;
                }

                &::after {
                    transform: rotate(45deg);
                    top: 0;
                }
            }
        }
    }
}
