.shop {
    // .shop__content
    &__content {
        @include container(960px, 75px);
        margin: 0 auto;
        position: relative;
        padding-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)));
        @media (max-width:767px) {
            padding-right: 35px;
            padding-left: 35px;
        }
    }
    // .shop__item
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        *:last-child {
            margin-bottom: 0;
        }
    }
    // .shop__img-link
    &__img-link {
        display: inline-block;
        img {
            max-width:100%;
            height: 145px;
            margin: 0 auto;
            object-fit: contain;
        }
        margin-bottom: 20px;
    }
    // .shop__balloon-link
    &__balloon-link {
        @extend .h3;
        margin-bottom: 15px;
    }
    // .shop__item-info
    &__item-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    // .shop__address
    &__address {
        display: inline-block;
        margin-bottom: 10px;
    }
    // .shop__phone
    &__phone {
        display: inline-block;
        margin-bottom: 10px;
    }
    // .shop__site
    &__site {
        display: inline-block;
        margin-bottom: 10px;
    }
    // .shop__mail
    &__mail {
        display: inline-block;
        margin-bottom: 10px;
    }
    // .shop__text
    &__text {
        display: inline-block;
        margin-bottom: 10px;
    }
    // .shop__btn
    &__btn {
        position: absolute;
        top: 50%;
        width: 24px;
        height: 54px;
        overflow: hidden;
        background: no-repeat center url('/static/images/index/map/arrow.svg');
        &:hover {
            color: $color-blue;
        }
        // .shop__btn_prev
        &_prev {
            transform: translateY(-50%) scaleX(-1);
            left: 5px;
        }
        // .shop__btn_next
        &_next {
            transform: translateY(-50%);
            right: 5px;
        }
        &.swiper-button-lock {
            display: none;
        }
    }
}