.video {
    // .video__wrap
    &__wrap {
        position: relative;
        padding-bottom: 56.3%;
        // min-height: 375px;
    }
    // .video__poster
    &__poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 20;
    }
    // .video__play
    &__play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        object-fit: cover;
        z-index: 22;
        cursor: pointer;
        @media (min-width:768px) {
            width: 100px;
            height: 100px;
        }
    }
    #player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: hidden;
    }
}
