/* font-family: "HelveticaNeueCyrRoman"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrRoman/HelveticaNeueCyrRoman.eot");
    src: url("../fonts/HelveticaNeueCyrRoman/HelveticaNeueCyrRoman.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrRoman/HelveticaNeueCyrRoman.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrRoman/HelveticaNeueCyrRoman.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "HelveticaNeueCyrBold"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrBold/HelveticaNeueCyrBold.eot");
    src: url("../fonts/HelveticaNeueCyrBold/HelveticaNeueCyrBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrBold/HelveticaNeueCyrBold.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrBold/HelveticaNeueCyrBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}
/* font-family: "HelveticaNeueCyrBoldItalic"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrBoldItalic/HelveticaNeueCyrBoldItalic.eot");
    src: url("../fonts/HelveticaNeueCyrBoldItalic/HelveticaNeueCyrBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrBoldItalic/HelveticaNeueCyrBoldItalic.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrBoldItalic/HelveticaNeueCyrBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: bold;
}
/* font-family: "HelveticaNeueCyrItalic"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrItalic/HelveticaNeueCyrItalic.eot");
    src: url("../fonts/HelveticaNeueCyrItalic/HelveticaNeueCyrItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrItalic/HelveticaNeueCyrItalic.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrItalic/HelveticaNeueCyrItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}
/* font-family: "HelveticaNeueCyrMedium"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrMedium/HelveticaNeueCyrMedium.eot");
    src: url("../fonts/HelveticaNeueCyrMedium/HelveticaNeueCyrMedium.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrMedium/HelveticaNeueCyrMedium.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrMedium/HelveticaNeueCyrMedium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}
/* font-family: "HelveticaNeueCyrLight"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.eot");
    src: url("../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}
/* font-family: "HelveticaNeueCyrMediumItalic"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrMediumItalic/HelveticaNeueCyrMediumItalic.eot");
    src: url("../fonts/HelveticaNeueCyrMediumItalic/HelveticaNeueCyrMediumItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrMediumItalic/HelveticaNeueCyrMediumItalic.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrMediumItalic/HelveticaNeueCyrMediumItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 500;
}
/* font-family: "HelveticaNeueCyrLightItalic"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrLightItalic/HelveticaNeueCyrLightItalic.eot");
    src: url("../fonts/HelveticaNeueCyrLightItalic/HelveticaNeueCyrLightItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrLightItalic/HelveticaNeueCyrLightItalic.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrLightItalic/HelveticaNeueCyrLightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 300;
}
/* font-family: "HelveticaNeueCyrBlack"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.eot");
    src: url("../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.ttf") format("truetype");
    font-style: normal;
    font-weight: 800;
}
/* font-family: "HelveticaNeueCyrBlackItalic"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrBlackItalic/HelveticaNeueCyrBlackItalic.eot");
    src: url("../fonts/HelveticaNeueCyrBlackItalic/HelveticaNeueCyrBlackItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrBlackItalic/HelveticaNeueCyrBlackItalic.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrBlackItalic/HelveticaNeueCyrBlackItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 800;
}
/* font-family: "HelveticaNeueCyrHeavy"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrHeavy/HelveticaNeueCyrHeavy.eot");
    src: url("../fonts/HelveticaNeueCyrHeavy/HelveticaNeueCyrHeavy.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrHeavy/HelveticaNeueCyrHeavy.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrHeavy/HelveticaNeueCyrHeavy.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
}
/* font-family: "HelveticaNeueCyrHeavyItalic"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrHeavyItalic/HelveticaNeueCyrHeavyItalic.eot");
    src: url("../fonts/HelveticaNeueCyrHeavyItalic/HelveticaNeueCyrHeavyItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrHeavyItalic/HelveticaNeueCyrHeavyItalic.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrHeavyItalic/HelveticaNeueCyrHeavyItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 900;
}
/* font-family: "HelveticaNeueCyrThin"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrThin/HelveticaNeueCyrThin.eot");
    src: url("../fonts/HelveticaNeueCyrThin/HelveticaNeueCyrThin.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrThin/HelveticaNeueCyrThin.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrThin/HelveticaNeueCyrThin.ttf") format("truetype");
    font-style: normal;
    font-weight: 200;
}
/* font-family: "HelveticaNeueCyrThinItalic"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrThinItalic/HelveticaNeueCyrThinItalic.eot");
    src: url("../fonts/HelveticaNeueCyrThinItalic/HelveticaNeueCyrThinItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrThinItalic/HelveticaNeueCyrThinItalic.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrThinItalic/HelveticaNeueCyrThinItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 200;
}
/* font-family: "HelveticaNeueCyrUltraLight"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrUltraLight/HelveticaNeueCyrUltraLight.eot");
    src: url("../fonts/HelveticaNeueCyrUltraLight/HelveticaNeueCyrUltraLight.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrUltraLight/HelveticaNeueCyrUltraLight.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrUltraLight/HelveticaNeueCyrUltraLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 100;
}
/* font-family: "HelveticaNeueCyrUltraLightItalic"; */
@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeueCyrUltraLightItalic/HelveticaNeueCyrUltraLightItalic.eot");
    src: url("../fonts/HelveticaNeueCyrUltraLightItalic/HelveticaNeueCyrUltraLightItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/HelveticaNeueCyrUltraLightItalic/HelveticaNeueCyrUltraLightItalic.woff") format("woff"),
    url("../fonts/HelveticaNeueCyrUltraLightItalic/HelveticaNeueCyrUltraLightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 100;
}