.form-title {
    @extend .h2;
    margin-bottom: 20px;
    text-align: center;
}
.form {
    // .form__fieldset
    &__fieldset {
        // .form-field
        .form-field {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            ul.errors {
                margin-top: 3px;
                color: $color-purple-nuts;
            }
            label {
                margin-bottom: 5px;
            }
            input, textarea {
                border: 1px solid $color-dark-gray;
            }
            input {
                padding: 0 15px;
                height: 50px;
            }
            textarea {
                padding: 10px 15px;
                min-height: 100px;
                resize: vertical;
            }
        }
    }
    // .form__confirm
    &__confirm {
        display: inline-block;
        margin-bottom: 30px;
        a {
            @extend .link;
        }
    }
    // .form__btn
    &__btn {
        display: flex;
        align-items: center;
        margin: 0 auto;
        height: 50px;
        padding: 0 30px;
        border: 1px solid $color-dark-gray;
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-white;
            background-color: $color-black;
        }
    }
    &  + .form-message {
        display: none;
    }
    &.success {
        height: 200px;
        overflow: hidden;
    }
    &.success + .form-message {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 15px;
        background-color: $color-white;
        font-family: $ff-helvetica;
        font-size: 30px;
        text-align: center;
    }
}
