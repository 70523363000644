*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
	outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
ul,
li {
  list-style: none;
}
fieldset,
option,
select,
input,
button,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  &:not(input){cursor: pointer;}
  outline: none;
  border: none;
  background: transparent;
  text-decoration: transparent;
}
a {
  color: inherit;
  text-decoration: transparent;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 16px;
  color: #000;
  font-family: $ff-helvetica;
  background: #fff;
	scroll-behavior: smooth;
  &.lock {
    overflow: hidden;
  }
}