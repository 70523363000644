.create {
    // background:$color-dark-gray url(/static/images/about/create/founded.png);
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    position: relative;
    height: 100vh;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: 2;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    // .create__wrap
    &__wrap {
        position: relative;
        z-index: 2;
        max-width: 860px;
        padding: calc(25px + (90 - 25) * ((100vw - 320px) / (1920 - 320))) 15px;
        @media (min-width:768px) {
            padding: calc(25px + (90 - 25) * ((100vw - 320px) / (1920 - 320))) 30px;
        }
        @media (min-width:1024px) {
            padding: calc(25px + (90 - 25) * ((100vw - 320px) / (1920 - 320))) 60px;
        }
        @media (min-width:1920px) {
            padding: 90px 60px;
        }
        *:last-child {
            margin-bottom: 0;
        }
    }
    // .create__date
    &__date {
        @extend .text;
        font-weight: bold;
        margin-bottom: 15px;
        color: $color-white;
    }
    // .create__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 20px;
        color: $color-white;
    }
    // .create__text
    &__text {
        @extend .text;
        margin-bottom: 15px;
        color: $color-white;
    }
}
