.big-slider {
    // .big-slider__wrap
    &__wrap {
        position: relative;
    }
    &__btn{
        align-self: flex-start;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        min-width: 240px;
        border: 1px solid $color-black;
        font-family: $ff-helvetica;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        transition: all 0.3s ease 0s;
        color: $color-white;
        background-color: $color-black;
        pointer-events: all;





    }

    // .big-slider__item
    &__item {
        position: relative;
        // padding-bottom: 56.25%;

        &_down{
            &:before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                                to top,
                                rgba(0, 0, 0, 0.9) 0%,
                                rgba(0, 0, 0, 0) 30%
                );
            }
        }
    }
    // .big-slider__info
    &__info {
        position: absolute;
        top: 100px;
        left: 0px;
        padding: 0 15px;
        z-index: 2;

        @media(min-width:768px) {
            left: 100px;
        }
        @media(min-width:1200px) {
            left: 200px;
        }

        // .big-slider__info_down
        &_down {
            top: auto;
            bottom: 50px;
        }

        // .big-slider__info_white
        &_white {
            .big-slider__name, .big-slider__h2, .big-slider__h3 {
                color: $color-white;
            }

            .big-slider__btn {
                background: transparent;
                border: 1px solid $color-white;
                &:hover{
                    color: $color-white;
                    background-color: rgba(0, 0, 0, 0.8);
                }
            }
            
        }

    }
    // .big-slider__name
    &__name {
        @extend .h4;
        margin-bottom: 20px;
        width: 100%;
        @media(min-width:768px) {
            width: 50%;
        }
        @media(min-width:1200px) {
            width: 50%;
        }
    }
    // .big-slider__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 10px;
    }
    // .big-slider__h3
    &__h3 {
        @extend .h3;
    }
    // .big-slider__img
    &__img {
        width: 100%;
        height: 100vh;
        min-height: 600px;
        object-fit: cover;
    }
    &__prev, &__next {
        display: none;
        @media(min-width:768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 60px;
            height: 60px;
            top: 50%;
            transform: translateY(-50%);
            background-color: $color-white;
            cursor: pointer;
            z-index: 3;
        }
        &.swiper-button-lock {
            display: none;
        }
    }
    // .big-slider__prev
    &__prev {
        left: 15px;
        @extend .product__prev;
    }
    // .big-slider__next
    &__next {
        right: 15px;
        @extend .product__next;
    }
    // .big-slider__pagination
    &__pagination {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        .swiper-pagination-bullet {
            height: 2px;
            width: 50px;
            background-color: $color-white;
            margin-right: 10px;
            transition: all 0.3s ease-in;
            &:last-child {
                margin-right: 0;
            }
            &-active {
                height: 5px;
            }
        }
        &.swiper-pagination-lock {
            display: none;
        }
    }
}