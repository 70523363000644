.map {
    // .map__wrap
    &__wrap {
        @include container();
        padding-top: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
    }
    // .map__content
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 65px;
    }
    // .map__h2
    &__h2 {
        @extend .h2;
        text-align: center;
        margin-bottom: 40px;
    }
    // .map__dropdown
    &__dropdown {
        position: relative;
        display: flex;
        align-items: center;
        &:after {
            content: url('/static/images/arrow.svg');
            position: absolute;
            right: 15px;
            width: 15px;
            top: 15px;
            height: auto;
            pointer-events: none;
        }
        @media (max-width: 599px) {
            flex-direction: column;
            &:after {
                top: 63px;
            }
        }
    }
    // .map__label
    &__label {
        @extend .h3;
        margin-right: 25px;
        @media (max-width: 599px) {
            margin: 0px 0px 25px 0px;
        }
    }
    // .map__select
    &__select {
        @extend .text;
        display: inline-flex;
        align-items: center;
        height: 48px;
        padding: 4px 40px 0 20px;
        border: 1px solid $color-dark-gray;
        appearance: none;
    }
}