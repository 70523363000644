.footer {
    background: #000000;
    color: #ffffff;
    // .footer__wrap
    &__wrap {
        @include container();
        display: flex;
        align-items: center;
        padding-top: 55px;
        padding-bottom: 55px;
    }
    // .footer__row
    &__row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    // .footer__column
    &__column {
        height: 100%;
        display: flex;
        // .footer__column_nav
        &_nav {
            flex: 0 1 250px;
            flex-direction: column;
            margin-right: calc(
                40px + (100 - 40) * ((100vw - 320px) / (1920 - 320))
            );
            @media (max-width: 849px) {
                align-self: flex-start;
            }
            @media (max-width: 499px) {
                flex: 1 1 100%;
                margin: 0px 0px 20px 0px;
            }
        }
        // .footer__column_links
        &_links {
            flex: 1 1 auto;
            @media (max-width: 499px) {
                flex: 1 1 100%;
            }
        }
        // .footer__column_copyright-block
        &_copyright-block {
            align-self: flex-end;
            flex-direction: column;
            flex: 0 1 auto;
            text-align: right;
            @media (max-width: 1199px) {
                flex: 1 1 100%;
                flex-direction: row;
                justify-content: center;
            }
            @media (max-width: 499px) {
                flex-direction: column;
                align-items: center;
                flex: 1 1 100%;
            }
        }
    }
    // .footer__logo
    &__logo {
        flex: 1 1 auto;
        text-align: center;
        margin-bottom: 30px;
        img {
            max-width: 100%;
        }
    }
    // .footer__social-block
    &__social-block {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    // .footer__social-link
    &__social-link {
        margin-bottom: 10px;
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
    // .footer__ul
    &__ul {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 849px) {
            flex-direction: column;
            align-items: flex-end;
        }
        @media (max-width: 499px) {
            align-items: center;
        }
    }
    // .footer__li
    &__li {
        margin: 0 calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));
        @media (max-width: 849px) {
            margin: 0px 0px 20px 0px;
        }
    }
    // .footer__link
    &__link {
        white-space: nowrap;
        &:hover{
            text-decoration: underline;
        }
    }
    // .footer__copyright
    &__copyright {
        font-size: 12px;
        line-height: calc(22 / 12 * 100%);
        color: $color-gray;
        a {
            color: $color-white;
            text-decoration: underline;
            transition: all 0.3s ease 0s;
            &:hover {
                color: $color-blue;
            }
        }
        @media (max-width: 1199px) {
            margin-right: 30px;
        }
        @media (max-width:499px) {
            margin: 0px 0px 10px 0px;
        }
    }
    // .footer__rules
    &__rules {
        font-size: 12px;
        line-height: calc(22 / 12 * 100%);
        color: $color-gray;
        white-space: nowrap;
    }
}
