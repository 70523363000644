.characteristic {
    background: $color-white-f3;
    padding-top: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
    // .characteristic__wrap
    &__wrap {
        @include container();
    }
    // .characteristic__h2
    &__h2 {
        @extend .h2;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 55px;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60px;
            height: 4px;
            background: $color-dark-gray;
        }
    }
    // .characteristic__row
    &__row {
        display: flex;
        @media (max-width:767px) {
            flex-wrap: wrap;
        }
    }
    // .characteristic__column
    &__column {
        flex: 0 1 50%;
        // .characteristic__column_content
        &_content {
            padding-right: 30px;
        }
        // .characteristic__column_img
        &_img {
            padding-left: 30px;
            img {
                margin: 0 auto;
                max-width: 100%;
            }
        }
        @media (max-width:767px) {
            flex: 1 1 100%;
            padding: 0px;
            margin-bottom: 30px;
            *:last-child {
                margin-bottom: 0;
            }
        }
    }
    // .characteristic__table
    &__table {
    }
    // .characteristic__table-content
    &__table-content {
        column-count: 2;
    }
}
