.banner {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    // .banner__img
    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
    // .banner__wrap
    &__wrap {
        @include container();
        position: absolute;
        padding-top: 15px;
        padding-bottom: 15px;
        left: 50%;
        top: 100px;
        transform: translateX(-50%);
        z-index: 20;
        &_white {
            .banner__h2, .banner__h1 {
                color: $color-white;
            }
        }
        &_down{
            left: 0;
            top: auto;
            bottom: 50px;
            margin: 0px;
            transform: unset;
            width: 100%;
            @media(min-width:768px) {
                width: 80%;
            }
            @media(min-width:1200px) {
                width: 50%;
            }

            .banner__h1, .banner__h2 {
                text-align: left;
            }

            .banner__content{
                align-items: flex-start;
            }
        }
    }
    // .banner__content
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    // .banner__h2
    &__h2 {
        @extend .h4;
        margin-bottom: 15px;
    }
    // .banner__h1
    &__h1 {
        @extend .h2;
    }
}
