.info {
    background: $color-white-f3;
    // .info__wrap
    &__wrap {
        @include container(1220px);
        padding-top: calc(50px + (110 - 50) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(50px + (110 - 50) * ((100vw - 320px) / (1920 - 320)));
        *:last-child {
            margin-bottom: 0;
        }
        text-align: center;
    }
    // .info__h3
    &__h3 {
        margin-bottom: 30px;
        font-family: $ff-helvetica;
        font-style: normal;
        font-weight: 200;
        font-size: 22px;
        line-height: calc(30 / 22 * 100%);
        color: $color-dark-gray;
        @media (min-width:768px) {
            font-size: 36px;
            line-height: calc(44 / 36 * 100%);
        }
    }
    // .info__text
    &__text {
        margin-bottom: 15px;
        font-family: $ff-helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: calc(24 / 16 * 100%);
        color: $color-dark-gray;
        @media (min-width:768px) {
            font-size: 22px;
            line-height: calc(30 / 22 * 100%);
        }
    }
}
