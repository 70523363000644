.content {
    position: relative;
    &.height {
        height: 100vh;
        min-height: 700px;
    }
    // .content__wrap
    &__wrap {
        @include container();
        position: relative;
        padding-top: 20px;
        padding-bottom: calc(0px + (80 - 0) * ((100vw - 320px) / (1920 - 320)));
        z-index: 2;
    }
    // .content__row
    &__row {
        display: flex;
        .content__column_img {
            margin: 0px 30px 0px 0px;
        }
        &_reverse {
            flex-direction: row-reverse;
            .content__column_img {
                margin: 0px 0px 0px 30px;
            }
        }
        @media (max-width:1023px) {
            flex-wrap: wrap;
            .content__column_img {
                margin: 0;
            }
        }
    }
    // .content__column
    &__column {
        *:last-child {
            margin-bottom: 0;
        }
        // .content__column_img
        &_img {
            flex: 0 1 calc(50% - 30px);
            @media (max-width:1023px) {
                flex: 1 1 100%;
                img {
                    max-width: 100%;
                }
            }
            @media(min-width:1024px) {
                position: relative;
                padding-bottom: 49%;
                min-height: 270px;
                max-height: 550px;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        // .content__column_content
        &_content {
            flex: 0 1 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 90px calc(85px + (180 - 85) * ((100vw - 1200px) / (1920 - 1200)));
            @media (max-width:1023px) {
                flex: 1 1 100%;
                padding: calc(30px + (90 - 30) * ((100vw - 320px) / (1199 - 320))) 0;
            }
            @media (min-width:1920px) {
                padding: 90px 180px;
            }
            // .content__column_bg
            @media(min-width:768px) {
                &.content__column_bg {
                    flex: 0 1 50%;
                }
            }
        }
        &_white {
            .content__before-title, .content__h2, .content__text {
                color: $color-white;
            }
            .content__btn {
                background-color: $color-white;
                border: 1px solid $color-white;
                &:hover{
                    color: $color-black;
                    background-color: rgba(255, 255, 255, 0.8);
                }
            }
            .content__line {
                background-color: $color-white;
            }
        }
    }
    // .content__before-title
    &__before-title {
        @extend .text;
        font-weight: bold;
        margin-bottom: 8px;
    }
    // .content__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 15px;
    }
    // .content__text
    &__text {
        @extend .text;
        margin-bottom: 30px;
    }
    // .content__btn
    &__btn {
        align-self: flex-start;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        min-width: 240px;
        border: 1px solid $color-black;
        font-family: $ff-helvetica;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        transition: all 0.3s ease 0s;

        &:hover{
            color: $color-white;
            background-color: $color-black;
        }
    }
    // .content__line
    &__line {
        display: none;
        @media (min-width:1024px) {
            margin-top: 55px;
            display: block;
            left: 0;
            width: 60px;
            height: 1px;
            background: $color-dark-gray;
        }
    }
    // .content__bg
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }
}
