.head {
    position: relative;
    .swiper-slide {
        position: relative;
        min-height: 500px;
        padding-bottom: 46.25%;
    }
    // .head__bg
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    // .head__wrap
    &__wrap {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        z-index: 2;
        pointer-events: none
    }
    // .head__container
    &__container {
        @include container(1600px);
        margin: 0 auto;
        &_white {
            .head__title, .head__subtitle {
                color: $color-white;
            }
            .head__btn {
                color: $color-black;
                background-color: $color-white;
                border: 1px solid $color-white;
                &:hover{
                    background-color: rgba(255, 255, 255, 0.8);
                }
            }
        }
    }
    // .head__title
    &__title {
        @extend .h2;
        margin-bottom: 20px;
    }
    // .head__subtitle
    &__subtitle {
        @extend .h3;
        margin-bottom: 30px;
    }
    // .head__btn
    &__btn {
        align-self: flex-start;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        min-width: 240px;
        border: 1px solid $color-black;
        font-family: $ff-helvetica;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        transition: all 0.3s ease 0s;
        color: $color-white;
        background-color: $color-black;
        pointer-events: all;

        &:hover{
            background-color: rgba(0, 0, 0, 0.8);
        }
    }
}
